<template>
    <div>
        <PageHeader screenName="Termo de adesão" :linkItems="linkItems"/>

        <SucessoModal
            :modalShow="modalSucesso.showModal"
            :typeModal="modalSucesso.typeModal"
            :caseModal="modalSucesso.caseModal"
            @confirmed="redirecionarLista"
        />

        <b-card>
            <b-row v-if="!usedProgressButton">
                <b-col xl="5">
                    <b-form-group
                        label="Selecione uma Campanha vigente*"
                        label-for="list-campaign-select"
                        label-class="selectCampaignLabel"
                    >
                        <v-select
                            id="list-campaign-select"
                            :options="campaigns"
                            v-model="selectedCampaign"
                            placeholder="Seleciona uma campanha para avançar"
                            label="campanha_descricao"
                            item-text="campanha_descricao"
                            item-value="id_campanha"
                            :clearable="false"
                        >
                            <span slot="no-options">Nenhuma campanha disponível.</span>
                        </v-select>
                    </b-form-group>
                </b-col>
                <b-col xl="7">
                    <b-button id="progress-button" 
                        class="buttonHeightInline" 
                        variant="custom-blue"
                        @click.once="prepareCampaign"
                        :disabled="selectedCampaign ? false : true"
                    >
                        <span class="align-middle">Avançar</span>
                    </b-button>
                </b-col>
            </b-row>
            <div v-else class="ml-n1">
                <b-col xl="12">
                    <div class="adhesionContractTitle">
                        {{ selectedCampaign.inicio_campanha ? moment(selectedCampaign.inicio_campanha).format('YYYY') + " - " : "" }} {{ selectedCampaign.campanha_descricao }}
                    </div>
                    <div class="d-flex adhesionContractBellow" @click.once="redirectHelp()">
                        <div>
                            <feather-icon
                                size="12"
                                icon="HelpCircleIcon"
                                class="text-custom-blue"
                            />
                        </div>
                        <div class="adhesionContractBellowTitle">
                            Dúvidas sobre o Termo de Adesão das Campanhas? Consulte a nossa Ajuda e Suporte.
                        </div>
                    </div>
                </b-col>
                <b-col xl="12" class="mt-2 mb-2 adhesionContractSubtitle">
                    Usar termo de adesão:
                </b-col>
                <validation-observer ref="simpleRules">
                    <b-row class="text-center mb-3 ml-1">
                        <b-col xl="5" :class="systemPatternSelected ? 'systemPatternBox' : 'customBox pointer'" @click.stop="systemPatternSelected = true">
                            <div :class="systemPatternSelected ? 'systemPatternBoxTitle' : 'customBoxTitle'">
                                Padrão do sistema
                            </div>
                            <div :class="systemPatternSelected ? 'systemPatternBoxSubtitle' : 'customBoxSubtitle'">
                                Termo de adesão gerado automaticamente pelo sistema para campanha criada.
                            </div>
                            <div>
                                <b-button 
                                    id="download-model" 
                                    :class="systemPatternSelected ? '' : 'disabledButton'"
                                    :variant="systemPatternSelected ? 'custom-blue' : 'outline-primary'"
                                    @click.prevent="downloadAdhesionContract"
                                    :disabled="systemPatternSelected ? false : true"
                                >
                                    <feather-icon
                                        size="18" 
                                        icon="DownloadIcon" 
                                        class="mr-50"
                                    />
                                    <span class="align-middle">Baixar modelo</span>
                                </b-button>
                            </div>
                        </b-col>
                        <div class="mx-1" />
                        <b-col v-if="customAdhesionContract" xl="6" :class="systemPatternSelected ? 'customBox pointer' : 'systemPatternBox'" @click.stop="systemPatternSelected = false">
                            <div :class="systemPatternSelected ? 'customBoxTitle' : 'systemPatternBoxTitle'">
                                Modelo personalizado (recomendado)
                            </div>
                            <div :class="systemPatternSelected ? 'customBoxSubtitle' : 'systemPatternBoxSubtitle'">
                                O arquivo deve estar no formato PDF e não poderá exceder 1MB.
                            </div>
                            <div
                                class="d-flex justify-content-center align-items-center text-left"
                            >
                                <b-button 
                                    id="download-custom-model" 
                                    :class="!systemPatternSelected ? 'customDownloadButtonResponsive' : 'disabledButton customDownloadButtonResponsive'"
                                    :variant="!systemPatternSelected ? 'custom-blue' : 'outline-primary'"
                                    @click.prevent="downloadAdhesionContract"
                                    :disabled="!systemPatternSelected ? false : true"
                                >
                                <div class="maxWidthAdhesionContractDescription">
                                    <feather-icon
                                    size="18" 
                                    icon="DownloadIcon" 
                                    class="mr-50"
                                    />
                                    <span class="align-middle">{{ customAdhesionContractDescription }}</span>
                                </div>
                                </b-button>
                                <div v-if="!systemPatternSelected" class="ml-2">
                                    <feather-icon
                                        size="24"
                                        icon="XIcon" 
                                        class="text-custom-blue pointer"
                                        @click.once="customAdhesionContract = false;"
                                    />
                                </div>
                            </div>
                        </b-col>
                        <b-col v-else xl="6" :class="systemPatternSelected ? 'customBox pointer' : 'systemPatternBox'" @click.stop="systemPatternSelected = false">
                            <div :class="systemPatternSelected ? 'customBoxTitle' : 'systemPatternBoxTitle'">
                                Modelo personalizado (recomendado)
                            </div>
                            <div :class="systemPatternSelected ? 'customBoxSubtitle' : 'systemPatternBoxSubtitle'">
                                O arquivo deve estar no formato PDF e não poderá exceder 1MB.
                            </div>
                            <div v-show="systemPatternSelected">
                                <b-button 
                                    id="upload-model" 
                                    class="disabledButton"
                                    variant="outline-primary"
                                    :disabled="true"
                                >
                                    <feather-icon
                                        size="18" 
                                        icon="UploadIcon" 
                                        class="mr-50"
                                    />
                                    <span class="align-middle">Fazer Upload</span>
                                </b-button>
                            </div>
                            <div
                                v-show="!systemPatternSelected"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="file"
                                    rules="mimes:application/pdf|size:1024"
                                >
                                    <div
                                        class="d-flex justify-content-center align-items-center text-left"
                                    >
                                        <div>
                                            <b-form-file
                                                v-model="file"
                                                @input="requiredFile = false"
                                                placeholder="Nenhum arquivo selecionado"
                                                drop-placeholder="Pode soltar o arquivo aqui"
                                                accept="application/pdf"
                                            />
                                        </div>
                                        <div v-if="file" class="ml-3 customXIconResponsive">
                                            <feather-icon
                                                size="24"
                                                icon="XIcon" 
                                                class="text-custom-blue pointer"
                                                @click.once="dropFileAndErrors"
                                            />
                                        </div>
                                    </div>
                                    <small v-if="!file && requiredFile" class="text-danger">
                                        <feather-icon
                                            size="14"
                                            icon="AlertCircleIcon"
                                        />
                                        É obrigatório selecionar um documento caso seja personalizado
                                    </small>
                                    <small v-if="file && errors[0]" class="text-danger">
                                        <feather-icon
                                            size="14"
                                            icon="AlertCircleIcon"
                                        />
                                        O documento deve ser do tipo PDF e ter no máximo 1 megabyte
                                    </small>
                                    <small v-if="file && errorApiFile" class="text-danger">
                                        <feather-icon
                                            size="14"
                                            icon="AlertCircleIcon"
                                        />
                                        {{ errorApiFile }}
                                    </small>
                                </validation-provider>
                            </div>
                        </b-col>
                    </b-row>
                </validation-observer>
                <b-col xl="12">
                    <b-button 
                        id="upload-model" 
                        variant="custom-blue"
                        @click.prevent="validationForm()"
                        :disabled="saving"
                    >
                        <feather-icon
                            size="18" 
                            icon="CheckIcon" 
                            class="mr-50"
                        />
                        <span class="align-middle">Salvar termo de adesão</span>
                        <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                    </b-button>
                </b-col>
            </div>
        </b-card>
    </div>
</template>
<script>
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import { BCard, BFormGroup, BRow, BCol, BButton, BFormFile } from 'bootstrap-vue'
import vSelect from "vue-select"
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { mimes, size } from '@validations'
import moment from 'moment'
import { adhesionContractTypes } from "@/enum/adhesionContractType";
import {tipoCampanhaEnum} from "@/enum/tipoCampanha";

export default {

    title: 'Termo de adesão',

    components: {
        PageHeader,
        BCard, BFormGroup, BRow, BCol, BButton, BFormFile,
        vSelect,
        SucessoModal,
        ValidationObserver, ValidationProvider
    },

    data(){
        return {
            linkItems: [
                {
                    name: 'Campanhas',
                    routeName: 'campanha-list'
                },
                {
                    name: 'Termo de adesão',
                    active: false
                }
            ],
            campaigns: [],
            selectedCampaign: null,
            usedProgressButton: false,
            systemPatternSelected: true,
            customAdhesionContract: false,
            customAdhesionContractDescription: '',
            file: null,
            saving: false,
            modalSucesso: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'criacao'
            },
            errorApiFile: null,
            requiredFile: false,
            mimes,
            size,
            moment
        }
    },

    mounted() {
        this.findCampaigns();
    },

    methods: {
        findCampaigns(){
            this.$http.get(this.$api.campanha(), {
                params: {
                    vigente: 1,
                    tipoCampanha: tipoCampanhaEnum.REGIONAL
                }
            })
            .then(({ data }) => {
                this.campaigns = data;
            }).catch(() => {
                this.$helpers.modalErroServidorPadrao();
            })
        },

        redirectHelp() {
            this.$router.push({ name: 'pages-faq' });
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if(!this.systemPatternSelected && !this.file && !this.customAdhesionContract){
                        this.requiredFile = true;
                    }else if(!this.systemPatternSelected && !this.file && this.customAdhesionContract){
                        this.openModalSucesso();
                    }else {
                        this.requiredFile = false;
                        this.saving = true;
                        this.saveAdhesionContract();
                    }
                }
            })
        },

        saveAdhesionContract() {
            let formData = new FormData();
            formData.append('id_campanha', this.selectedCampaign.id_campanha);
            if(this.systemPatternSelected){
                formData.append('tipo', adhesionContractTypes.PADRAO)
            }else{
                formData.append('arquivo', this.file);
                formData.append('tipo', adhesionContractTypes.PERSONALIZADO);
            }

            let headers = {
                header : {
                    'Content-Type' : 'multipart/form-data'
                }
            }

            this.$http.post(this.$api.saveAdhesionContract(), formData, headers).then(() => {
                this.saving = false
                this.openModalSucesso();
            }).catch(({ response: { data } }) => {
                this.handleErrors(data);
                this.saving = false
            })
        },

        handleErrors(errorData){
            let error = errorData.errors;
            if(typeof error == 'string') {
                this.$helpers.modalErroServidorPadrao();
            }else if(typeof error == 'undefined'){
                this.$helpers.modalErroServidorPadrao();
            }else{
                if(error['id_campanha'] || error['tipo'] || error['arquivo']){
                    this.$helpers.modalErroServidorPadrao();
                }
            }
        },

        openModalSucesso() {
            this.modalSucesso.showModal = true;
        },

        redirecionarLista() {
            this.$router.push({ name: 'campanha-list' });
        },

        dropFileAndErrors() {
            this.file = null;
            this.errorApiFile = null;
        },

        prepareCampaign(){
            this.usedProgressButton = true;
            if(this.selectedCampaign.termo && this.selectedCampaign.termo[0]){
                if(this.selectedCampaign.termo[0].tipo == adhesionContractTypes.PADRAO){
                    this.systemPatternSelected = true;
                }else if (this.selectedCampaign.termo[0].tipo == adhesionContractTypes.PERSONALIZADO){
                    this.systemPatternSelected = false;
                    this.customAdhesionContract = true;
                    this.customAdhesionContractDescription = this.selectedCampaign.termo[0].anexo.descricao
                }
            }
        },

        downloadAdhesionContract(){
            this.$http.get(this.$api.downloadAdhesionContract(this.selectedCampaign.id_campanha), {
                responseType: "blob",
                params: {
                    tipo: this.systemPatternSelected ? adhesionContractTypes.PADRAO : adhesionContractTypes.PERSONALIZADO
                }
            })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = response.headers['content-name-file']
                link.click()
                URL.revokeObjectURL(link.href)
            })
            .catch(() => {
                this.$helpers.modalErroServidorPadrao();
            });
        }
    }
}
</script>
<style>
    .selectCampaignLabel{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #5E5873;
    }
    .buttonHeightInline{
        margin-top: 19px;
    }
    .adhesionContractTitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #5E5873;
    }
    .adhesionContractBellow{
        width: fit-content;
        cursor: pointer;
    }
    .adhesionContractBellowTitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.366667px;
        color: #2772C0;
        margin-top: 6px;
        margin-left: 5px;
    }
    .adhesionContractSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #5E5873;
    }
    .systemPatternBox{
        height: 180px !important;
        min-width: 320px;
        border: 1px solid #2772C0;
        border-radius: 6px;
        background-color: #E5EEF7;
    }
    .systemPatternBoxTitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 26px;
        color: #2772C0;
        margin-top: 20px;
    }
    .systemPatternBoxSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #2772C0;
        width: 377px ;
        margin: 15px auto;
    }
    .customBox{
        height: 180px !important;
        min-width: 320px;
        border: 1px solid white;
        border-radius: 6px;
        background-color: #F6F7F8;
    }
    .customBoxTitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 26px;
        color: #5E5873;
        margin-top: 20px;
    }
    .customBoxSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #6E6B7B;
        width: 377px;
        margin: 15px auto;
    }
    .disabledButton{
        color: white !important;
        background-color: #B9B9C3 !important;
        border: 1px solid white !important;
    }
    .custom-file {
        width: 105%;
    }
    .custom-file-input:lang(en) ~ .custom-file-label::after {
        content: 'Escolher arquivo';
    }
    .custom-file-label::after {
        color: white;
        background-color: #2772C0;
    }
    .pointer {
        cursor: pointer;
    }
    .maxWidthAdhesionContractDescription {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
    }
    @media (min-width: 1200px) and (max-width: 1300px) {
        .systemPatternBoxSubtitle, .customBoxSubtitle {
            width: 320px;
        }
        .systemPatternBox, .customBox {
            height: 200px !important;
        }
        .systemPatternBoxTitle{
            height: 50px;
        }
        .customBoxTitle{
            height: 50px;
        }
    }
    @media (min-width: 320px) and (max-width: 501px) {
        .systemPatternBoxSubtitle, .customBoxSubtitle {
            width: 150px;
        }
        .systemPatternBox, .customBox {
            min-width: 260px;
            height: 270px !important;
        }
    }
    @media (min-width: 391px) and (max-width: 501px) {
        .customDownloadButtonResponsive{
            max-width: 250px !important;
        }
        .custom-file {
            width: 280px;
        }
    }
    @media (max-width: 390px) {
        .customXIconResponsive{
            margin-left: 5px !important;
        }
        .customDownloadButtonResponsive{
            max-width: 150px !important;
        }
        .custom-file {
            width: 240px;
        }
    }
    @media (max-width: 329px) {
        .systemPatternBox{
            height: 300px !important;
        }
    }
</style>
